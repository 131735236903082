import React from "react";
import { Box, Typography, Button } from "@mui/material";
import "./middle.scss";
import logo1 from "../../../assets/images/ProjectsPage/logo1.svg";
import logo2 from "../../../assets/images/ProjectsPage/logo2.svg";
import logo3 from "../../../assets/images/ProjectsPage/logo3.svg";
import logo4 from "../../../assets/images/ProjectsPage/logo4.svg";
import logo5 from "../../../assets/images/ProjectsPage/logo5.svg";
import logo6 from "../../../assets/images/ProjectsPage/logo6.svg";
import clock from "../../../assets/images/ProjectsPage/clock.svg";
import { useNavigate } from "react-router-dom";

const data = [
  {
    id: 1,
    logo: logo2,
    title: "Location Analytics for Delivery Optimisation",
    description:
      "Utilize GIS and location data to analyze the geographical spread of orders and delivery efficiency. Implement models to predict high-demand areas and optimize delivery logistics accordingly. Use Python to analyze traffic data and weather conditions impacting delivery times and customer satisfaction.",
    prerequisites: "Power BI, Tableau",
    hours: 100,
    link: "Swiggy",
  },
  {
    id: 2,
    logo: logo1,
    title: "Government of Telangana - Agriculture Price Estimation",
    description:
      "Develop a predictive model using historical market data and real-time weather information to forecast crop prices. Implement machine learning techniques to refine accuracy based on trends and external factors. Provide farmers with accessible dashboards for current market conditions and predictions.",
    prerequisites: "Power BI, Tableau",
    hours: 100,
    link: "Government_Of_Telangana",
  },
  {
    id: 3,
    logo: logo3,
    title: "Naukri - Job Market Analysis",
    description:
      "Examine the job postings and applicant data to identify trends in industries, roles, and geographies. Use data visualisation to create interactive dashboards that allow users to explore job market dynamics. Implement machine learning algorithms to recommend jobs to users based on their profiles and previous interactions.",
    prerequisites: "Power BI, Tableau",
    hours: 100,
    link: "Naukri",
  },
  {
    id: 4,
    logo: logo4,
    title: "Zerodha - Financial Market Analysis Tool",
    description:
      "Aggregate financial data from multiple sources using advanced SQL techniques. Analyze historical market trends and correlations between different financial instruments using Python. Develop a set of visual analytic tools in Python to help users visualize market trends and potential investment opportunities.",
    prerequisites: "Power BI, Tableau",
    hours: 100,
    link: "Zerodha",
  },
  {
    id: 5,
    logo: logo5,
    title: "Myntra - Marketing and Sales Optimization",
    description:
      "Analyze customer interaction data and purchase history to optimize marketing campaigns and product placements. Utilize machine learning to predict customer buying behavior and personalize marketing efforts. Develop dashboards in Tableau to track marketing effectiveness and sales trends in real-time.",
    prerequisites: "Power BI, Tableau",
    hours: 100,
    link: "Myntra",
  },
  {
    id: 6,
    logo: logo6,
    title: "Apollo - Health Insurance Optimization",
    description:
      "Analyze historical claim data to identify patterns in claims and optimize insurance operations. Use predictive analytics to forecast claim amounts and frequencies, assisting in premium setting and risk assessment. Develop interactive dashboards for insurers to monitor claims processes, customer feedback, and operational efficiency.",
    prerequisites: "Power BI, Tableau",
    hours: 100,
    link: "Apollo",
  },
];

const Card = ({ targetFeaturedProjects }) => {
  const navigate = useNavigate();
  const handleClick = (item) => {
    navigate(`/projects/interest/${item.link}`);
  };
  return (
    <Box sx={{ padding: "0px 10px" }}>
      <Typography
        ref={targetFeaturedProjects}
        className="header-middle-project"
        style={{ scrollMarginTop: "80px" }}
      >
        <span style={{ color: "black" }}>Featured </span>Projects
      </Typography>
      <Typography className="normal-middle-project">
        Find interesting projects and get started with an execution plan
      </Typography>
      <Box className="top-wrapper-middle-project">
        {data.map((item) => (
          <Box className="card-wrapper-middle-project">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                component="img"
                height="20px"
                width="auto"
                src={item.logo}
              ></Box>
              <Box display="flex" gap="5px" alignItems="center">
                <Box component="img" src={clock}></Box>
                <Typography className="textHR-middle-project">
                  {item.hours} hours
                </Typography>
              </Box>
            </Box>
            <Typography className="header-text-middle-project">
              {item.title}
            </Typography>
            <Typography className="normal-text-middle-project">
              {item.description}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box>
                <Typography className="prerequisitesH-middle-project">
                  Prerequisites
                </Typography>
                <Typography className="prerequisites-middle-project">
                  {item.prerequisites}
                </Typography>
              </Box>
              <Button
                onClick={() => {
                  handleClick(item);
                }}
                className="btn-middle-project"
              >
                Interested
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Card;
