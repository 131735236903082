import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Typography, TextField, InputAdornment, Button } from "@mui/material";
import header_img from "../../../assets/images/ProjectsPage/header_img.svg";
import SearchIcon from "@mui/icons-material/Search";
import "./header.scss";
import { useParams } from "react-router-dom";
import logo1 from "../../../assets/images/ProjectsPage/logo1.svg";
import logo2 from "../../../assets/images/ProjectsPage/logo2.svg";
import logo3 from "../../../assets/images/ProjectsPage/logo3.svg";
import logo4 from "../../../assets/images/ProjectsPage/logo4.svg";
import logo5 from "../../../assets/images/ProjectsPage/logo5.svg";
import logo6 from "../../../assets/images/ProjectsPage/logo6.svg";

function Header({ targetFeaturedProjects }) {
  const { project_type } = useParams();

  const mainRouteData = {
    title: "Build Projects with us",
    description:
      "Transform Your Skills into Real-World Solutions with Our Guided Projects and Collaborative Community",
  };

  const dynamicRouteData = {
    Data_Analytics_Projects: {
      title: "Data Analytics Projects",
      description:
        "Build a Data Analytics project to showcase your skills in data collection, analysis, and visualisation. This project demonstrates your ability to transform raw data into actionable insights using various analytical tools and techniques,",
    },
    Data_Science_Projects: {
      title: "Data Science Projects",
      description:
        "Build a Data Analytics project to showcase your skills in data collection, analysis, and visualisation. This project demonstrates your ability to transform raw data into actionable insights using various analytical tools and techniques,",
    },
    Data_Visualization_Projects: {
      title: "Data Visualization Projects",
      description:
        "Build a Data Analytics project to showcase your skills in data collection, analysis, and visualisation. This project demonstrates your ability to transform raw data into actionable insights using various analytical tools and techniques,",
    },
    Data_Engineering_Projects: {
      title: "Data Engineering Projects",
      description:
        "Build a Data Analytics project to showcase your skills in data collection, analysis, and visualisation. This project demonstrates your ability to transform raw data into actionable insights using various analytical tools and techniques,",
    },
    Devops_Projects: {
      title: "Devops Projects",
      description:
        "Build a Data Analytics project to showcase your skills in data collection, analysis, and visualisation. This project demonstrates your ability to transform raw data into actionable insights using various analytical tools and techniques,",
    },
    Ux_Ui_Design_Projects: {
      title: "UX/UI Projects",
      description:
        "Build a Data Analytics project to showcase your skills in data collection, analysis, and visualisation. This project demonstrates your ability to transform raw data into actionable insights using various analytical tools and techniques,",
    },
  };

  const dataToDisplay = project_type
    ? dynamicRouteData[project_type]
    : mainRouteData;

  const data = [
    {
      id: 1,
      logo: logo2,
      title: "Location Analytics for Delivery Optimisation",
      description:
        "Utilize GIS and location data to analyze the geographical spread of orders and delivery efficiency. Implement models to predict high-demand areas and optimize delivery logistics accordingly. Use Python to analyze traffic data and weather conditions impacting delivery times and customer satisfaction.",
      prerequisites: "Power BI, Tableau",
      hours: 100,
    },
    {
      id: 2,
      logo: logo1,
      title: "Government of Telangana - Agriculture Price Estimation",
      description:
        "Develop a predictive model using historical market data and real-time weather information to forecast crop prices. Implement machine learning techniques to refine accuracy based on trends and external factors. Provide farmers with accessible dashboards for current market conditions and predictions.",
      prerequisites: "Power BI, Tableau",
      hours: 100,
    },
    {
      id: 3,
      logo: logo3,
      title: "Naukri - Job Market Analysis",
      description:
        "Examine the job postings and applicant data to identify trends in industries, roles, and geographies. Use data visualisation to create interactive dashboards that allow users to explore job market dynamics. Implement machine learning algorithms to recommend jobs to users based on their profiles and previous interactions.",
      prerequisites: "Power BI, Tableau",
      hours: 100,
    },
    {
      id: 4,
      logo: logo4,
      title: "Zerodha - Financial Market Analysis Tool",
      description:
        "Aggregate financial data from multiple sources using advanced SQL techniques. Analyze historical market trends and correlations between different financial instruments using Python. Develop a set of visual analytic tools in Python to help users visualize market trends and potential investment opportunities.",
      prerequisites: "Power BI, Tableau",
      hours: 100,
    },
    {
      id: 5,
      logo: logo5,
      title: "Myntra - Marketing and Sales Optimization",
      description:
        "Analyze customer interaction data and purchase history to optimize marketing campaigns and product placements. Utilize machine learning to predict customer buying behavior and personalize marketing efforts. Develop dashboards in Tableau to track marketing effectiveness and sales trends in real-time.",
      prerequisites: "Power BI, Tableau",
      hours: 100,
    },
    {
      id: 6,
      logo: logo6,
      title: "Apollo - Health Insurance Optimization",
      description:
        "Analyze historical claim data to identify patterns in claims and optimize insurance operations. Use predictive analytics to forecast claim amounts and frequencies, assisting in premium setting and risk assessment. Develop interactive dashboards for insurers to monitor claims processes, customer feedback, and operational efficiency.",
      prerequisites: "Power BI, Tableau",
      hours: 100,
    },
  ];

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredProjects = data.filter((project) => {
    const query = searchQuery.toLowerCase();
    return (
      project.title.toLowerCase().includes(query) ||
      project.description.toLowerCase().includes(query) ||
      project.prerequisites.some((prerequisite) =>
        prerequisite.toLowerCase().includes(query)
      )
    );
  });

  return (
    <Box
      className="header-container"
      sx={{
        background: "white",
        borderRadius: "10px",
        padding: { xs: "10px", md: "50px" },
        margin: { xs: "20px", md: "35px" },
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        className="text-container"
        sx={{
          padding: "10px",
        }}
      >
        <Button
          className="button-container"
          sx={{
            marginTop: { xs: "15px", md: "0px" },
            borderRadius: "7px",
            background: "linear-gradient(91deg, #7234F7 0%, #FE7465 130.98%)",
            color: "white",
            padding: { xs: "7px", md: "25px" },
            textTransform: "none",
            width: { xs: "40%", md: "25%" },
            height: { xs: "35x", md: "36px" },
            fontSize: { xs: "70%", md: "90%" },

            "&:hover": {
              background: "linear-gradient(91deg, #7234F7 0%, #FE7465 120%)",
              cursor: "default",
            },
          }}
        >
          Completely free
        </Button>
        <Typography
          sx={{
            padding: "auto",
            marginTop: "10px",
            fontSize: { xs: "165%", md: "200%" },
            color: "#260079",
            fontWeight: "600",
            fontFamily: "Outfit",
            lineHeight: { xs: "30px" },
          }}
        >
          {dataToDisplay?.title}
        </Typography>
        <Typography
          sx={{
            marginTop: { xs: "20px", md: "10px" },
            fontSize: { xs: "90%", md: "100%" },
            fontFamily: "Outfit",
          }}
        >
          {dataToDisplay?.description}
        </Typography>
        <Box>
          {/* <TextField
            id="search"
            placeholder="Search for projects......."
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            sx={{
              marginLeft: { xs: "0px", md: "0px" },
              marginTop: "20px",
              "& .MuiOutlinedInput-root": {
                height: { xs: "45px", md: "45px" },
                width: { xs: "100%", md: "85%" },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
              "& input::placeholder": {
                fontFamily: "Outfit",
                fontWeight: "normal",
                color: "black",
                fontSize: { xs: "14px", md: "15px" },
              },
              marginBottom: { xs: "20px" },
            }}
          /> */}
          <Button
            onClick={() => {
              if (targetFeaturedProjects.current) {
                // Scroll the div into view with smooth behavior
                targetFeaturedProjects.current.scrollIntoView({
                  behavior: "smooth",
                });
              }
            }}
            className="btn-top-project"
          >
            Start Project
          </Button>

          {/* <Box>
        {filteredProjects.map((project, index) => (
          <Box key={index} sx={{ marginTop: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
            <Typography variant="h6">{project.title}</Typography>
            <Typography variant="body2">{project.description}</Typography>
            <Typography variant="body2"><strong>Prerequisites:</strong>{Array.isArray(project.prerequisites) ? project.prerequisites.join(', ') : 'No prerequisites available'}</Typography>
          </Box>
        ))}
      </Box> */}
        </Box>
      </Box>
      <Box
        className="header_img"
        sx={{
          display: "flex",
          marginBottom: { xs: "15px", md: "0px" },
          flexDirection: { xs: "row", md: "row-reverse" },
          width: { xs: "80%", md: "35%" },
        }}
      >
        <img
          src={header_img}
          alt="header_img"
          style={{
            width: "90%",
            height: "auto",
          }}
        />
      </Box>
    </Box>
  );
}

export default Header;
