import { Box, Typography } from "@mui/material"
import CourseDescription from "./CourseDescription"
import NavBar from "./../../commonComponents/navbar/Navbar"
import Footer from "../../commonComponents/footer/Footer"
import AddWrapper from "../AdWrapper"
import { getSearchedCareerResults } from "../../../services/service-index";
import { HttpStatusCode } from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import CVLoader from "../../commonComponents/loader/loader"
import { Helmet } from "react-helmet"

const CourseDescriptionPage = (props) => {
  const [loader, setLoader] = useState(true)
  const { pathname } = useLocation();
  let { programId } = useParams();
  const [content, setContent] = useState(props.content);
  const navigate = useNavigate();

  programId = programId.split("-").pop();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (programId) {
      const payload = { _id: programId };
      getSearchedCareerResults(payload).then((response) => {
        if (response.status === HttpStatusCode.Ok) {
          setContent(response.data?.results?.[0] || []);
        }
      }).catch((err) => console.log(err?.message)).finally(() => setLoader(false));
    }
  }, [programId])

  const onClickHandler = (event, navigationPath) => {
    event.preventDefault();
    navigate(navigationPath);
  };


  const keywords = content?.instituteName;
  const keywords2 = content?.tags.join(','); 

  console.log(keywords,',' ,keywords2);
  console.log("CourseDescriptionPage", content);


  return (
    <>
      <Helmet>
        <title>{content ? content.title : 'Course Description'}</title>
        <meta name="description" content={content ? content.description : 'Course Description'} />
        <meta name="keywords" content={`${keywords}, ${keywords2}`} />
      </Helmet>
    {loader && programId ? <Box sx={{display:"flex", justifyContent:"center", pt:"100px"}}>
      <CVLoader /> 
    </Box>: 
    <> {
      content &&
       <Box sx={{ background: "#F3F4FD" }}>
        <NavBar />
        <Typography
          className="courseDescNavContent"
          sx={{
            fontSize: { md: "16px", xs: "14px" },
            p: { xs: "23px 16px 32px 16px", md: "36px 32px 29px 32px" }
          }}
        >
          <span
            className="tag"
            onClick={(e) => onClickHandler(e, "/")}
          >
            Home
          </span>
          &gt;&gt;
          <span
            className="tag"
            onClick={(e) => {
              e.preventDefault();
              if (props.handleClose) {
                props.handleClose();
              } else {
                onClickHandler(e, "/findyourcareer")
              }
            }}
          >
            Find Your Career
          </span>
          &gt;&gt;
          {content?.title}
        </Typography>

        <Box sx={{ display: "flex", width: "100%", flexDirection: { xs: "column", md: "row" }, px: { xs: "16px", md: "32px" }, gap: "25px", justifyContent: { xs: "center", md: "space-between" }, }} >
          <Box sx={{ width: { xs: "100%", md: "72%" }, gap: { xs: "16px", md: "20px" } }}>
            <CourseDescription course={content} />
          </Box>
          <Box className="AddsDiv " sx={{ width: { xs: "100%", md: "28%" }, fontSize: "30px" }}>
            <AddWrapper />
          </Box>
        </Box>

        <Footer />

      </Box>
    }</>
   } </>)
}

export default CourseDescriptionPage;