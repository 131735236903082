import { Typography, Box } from "@mui/material";
import certificate from "../../../assets/images/courseOutcomes/certificate.svg";
import coding from "../../../assets/images/courseOutcomes/coding.svg";
import resume from "../../../assets/images/courseOutcomes/resume.svg";
import suitcase from "../../../assets/images/courseOutcomes/suitcase.svg";
import "../courseOutcomes/CourseOutcomes.scss";

const CourseOutcomes = () => {
  const data = [
    {
      image: coding,
      text1: "Become a successful developer",
      text2:
        "Learn the course in a fully immersive environment and gain knowledge at a professional level.",
    },
    {
      image: certificate,
      text1: "Get Certified",
      text2:
        "Get a certificate upon successful completion, which adds great weightage to your portfolio.",
    },
    {
      image: suitcase,
      text1: "Launch your career",
      text2: "Launch your career at a substantial velocity.",
    },
    {
      image: resume,
      text1: "Support in building CV & Portfolio",
      text2: "Coursevita’s CV assistance program makes your profile stand out.",
    },
  ];

  return (
    <Box className="courseOutcomes" sx={{ mt: { md: "32px" } }}>
      <Typography
        className="title"
        sx={{ p: { md: "0px 10px 40px 10px", xs: "16px 10px" },fontSize: { md: "36px", xs: "20px" } }}
      >
        Course <span style={{ color: "#FF7262" }}>Outcomes</span>
      </Typography>
      <Box
        className="cardsWrapper"
        sx={{
          p: { xs: "0 16px 30px 16px", sm: "0 125px 60px 125px" },
          gap: { xs: "16px", md: "24px" },
        }}
      >
        {data?.map((item, index) => (
          <Box
            key={index}
            className="card"
            sx={{ width: { xs: "100%", md: "calc(50% - 16px)" }, p:{md:"26px 30px", xs:"26px 16px"} }}
          >
            <Box
              src={item.image}
              alt=""
              component="img"
              sx={{ width: {md:"48px", xs:"36px"}, height:  {md:"48px", xs:"36px"}, mb: {xs:"16px" ,md:"27px"}}}
            />
            <Typography
              className="text1"
              sx={{ fontSize: { md: "20px", xs: "16px" } }}
            >
              {item.text1}
            </Typography>
            <Typography className="text2">{item.text2}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CourseOutcomes;
