import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  Menu,
  Popover,
  Toolbar,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// import AIIcon from "../../../assets/images/navbar/AIIcon.svg";
// import cyberSecurity from "../../../assets/images/navbar/cyberSecurity.png";
// import softwareDevelopment from "../../../assets/images/navbar/softwareDevelopment.svg";
// import ArrowNavbar from "../../../assets/images/navbar/ArrowNavbar.svg";
// import ReactIcon from "../../../assets/images/navbar/React.svg";
import mern from "../../../assets/images/navbar/mern.svg";
// import mean from "../../../assets/images/navbar/mean.svg";
// import dataSciencebw from "../../../assets/images/navbar/dataSciencebw.svg";
// import dataEngg from "../../../assets/images/navbar/dataEngg.svg";
// import cyberSecuritybw from "../../../assets/images/navbar/cyberSecuritybw.png";
// import GenAI from "../../../assets/images/navbar/GenAI.svg";
// import promptEngg from "../../../assets/images/navbar/promtEngg.svg";
// import machineLearning from "../../../assets/images/navbar/machineLearning.svg";
import mlOps from "../../../assets/images/navbar/MLops.svg";
import fullStackicon from "../../../assets/images/navbar/fullStack.svg";

import dataVisualization from "../../../assets/images/navbar/dataVisualization.svg";
import dataAnalytics from "../../../assets/images/navbar/dataAnalytics.svg";
import dropArrow from "../../../assets/images/navbar/back.gif";
import closeArrow from "../../../assets/images/navbar/closeArrow.svg";
import dataScience from "../../../assets/images/navbar/dataScience.svg";
import horizontalLogo from "../../../assets/images/footer/horizontalLogo.svg";
import MenuIcon from "../../../assets/images/MenuIcon.svg";
import upArrow from "../../../assets/images/navbar/upward.png";
import downArrow from "../../../assets/images/downArrow.svg";
import leftArrow from "../../../assets/images/leftArrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "./Navbar.scss";
import { useState } from "react";

const Navbar = () => {
  const location = useLocation();

  const [isDropdownDesktopVisible, setIsDropdownDesktopVisible] =
    useState(false);
  const [isDropdownMobileVisible, setIsDropdownMobileVisible] = useState(false);

  const currentPath = location.pathname;
  const navigate = useNavigate();
  const courses = [
    // {
    //   title: "Frontend Developer-React",
    //   imageSource: ReactIcon,
    //   courseCategory: "Software Development",
    //   courseHeader: "Frontend development",
    //   path: "/coursePreview",
    // },
    // {
    //   title: "MERN Stack",
    //   imageSource: mern,
    //   courseCategory: "Software Development",
    //   courseHeader: "FullStack development",
    //   path: "/fullStackCourse",
    // },
    // {
    //   title: "MEAN Stack",
    //   imageSource: mean,
    //   courseCategory: "Software Development",
    //   path: "/coursePreview",
    // },
    // {
    //   title: "Data Analytics",
    //   imageSource: dataAnalytics,
    //   courseCategory: "Data Science",
    //   path: "/courses/dataAnalytics",
    // },
    {
      title: "Data Science",
      imageSource: dataScience,
      courseCategory: "Data Science",
      path: "/courses/dataScience",
    },
    // {
    //   title: "Data Visualization",
    //   imageSource: dataVisualization,
    //   courseCategory: "Data Science",
    //   path: "/courses/dataVisualization",
    // },
    {
      title: "ML Ops Training",
      imageSource: mlOps,
      courseCategory: "Data Science",
      path: "/courses/mlOpsTraining",
    },
    {
      title: "Full Stack Development",
      imageSource: fullStackicon,
      courseCategory: "Data Science",
      path: "/courses/fullStackDevelopment",
    },
    // {
    //   title: "Data Engineering",
    //   imageSource: dataEngg,
    //   courseCategory: "Data Science",
    //   path: "/coursePreview",
    // },
    // {
    //   title: "Cyber Security",
    //   imageSource: cyberSecuritybw,
    //   courseCategory: "Cyber Security",
    //   path: "/coursePreview",
    // },
    // {
    //   title: "Gen AI",
    //   imageSource: GenAI,
    //   courseCategory: "AI & Machine Learning",
    //   path: "/coursePreview",
    // },
    // {
    //   title: "Prompt Engineering",
    //   imageSource: promptEngg,
    //   courseCategory: "AI & Machine Learning",
    //   path: "/coursePreview",
    // },
    // {
    //   title: "Machine Learning",
    //   imageSource: machineLearning,
    //   courseCategory: "AI & Machine Learning",
    //   path: "/coursePreview",
    // },
  ];
  // const [activeCourseCategory, setActiveCourseCategory] = useState("");
  const [filteredCourses, setFilteredCourses] = useState();

  const onClickHandler = (event, navigationPath) => {
    setOpenourse(false);
    event.preventDefault();
    navigate(navigationPath);
  };
  const pages = [
    // { title: "Find your career", path: "/findyourcareer" },
    { title: "Blogs", path: "/blogs" },

    // { title: "Career Counselling", path: "/careerCounselling" },
  ];
  const mobilePages = [
    // { title: "Find your career", path: "/findyourcareer" },
    { title: "Blogs", path: "/blogs" },
    // { title: "Career Counselling", path: "/careerCounselling" },
    // { title: "Register", path: "/register" },
    // { title: "Login", path: "/login" },
  ];

  const courseCategories = [
    // {
    //   title: "Software Development",
    //   categoryImage: softwareDevelopment,
    // },
    {
      title: "Data Science",
      categoryImage: dataScience,
    },
    // {
    //   title: "Cyber Security",
    //   categoryImage: cyberSecurity,
    // },
    // {
    //   title: "AI & Machine Learning",
    //   categoryImage: AIIcon,
    // },
  ];
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElCourses, setAnchorElCourses] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  // const handleOpenCoursesMenu = (event) => {
  //   setAnchorElCourses(event.currentTarget);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseCoursesMenu = () => {
    setAnchorElCourses(null);
    setFilteredCourses([]);
  };

  const handleFilter = (category) => {
    // setActiveCourseCategory(category);
    const filtered = courses?.filter(
      (course) => course.courseCategory.toLowerCase() === category.toLowerCase()
    );
    setFilteredCourses(filtered);
  };
  const [openCourse, setOpenourse] = useState(false);
  const [expanded, setExpanded] = useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const toggleDrawer = (newOpen) => () => {
    setOpenourse(newOpen);
    if (!newOpen) {
      setFilteredCourses([]);
      setExpanded("");
    }
  };
  const open = Boolean(anchorElCourses);
  const id = open ? "simple-popover" : undefined;

  const renderSubCourses = () => {
    return (
      <>
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            m: { md: "25px 0 10px 0", xs: "42px" },
          }}
        >
          {filteredCourses?.map((course, index) => (
            <Box key={index}>
              {course && course.courseHeader ? (
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#7234F7",
                    mb: "10px",
                    fontFamily: `"Outfit",sans-serif`,
                  }}
                >
                  {course.courseHeader}
                </Typography>
              ) : (
                ""
              )}

              <Box
                className="navbarCourseCategory"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "26px",
                  mb: "10px",
                  p: "8px",
                }}
                onClick={(e) => {
                  onClickHandler(e, course.path);
                  handleCloseCoursesMenu();
                }}
              >
                <Box
                  component="img"
                  src={course.imageSource}
                  sx={{ height: "24px", width: "24px" }}
                />
                <Typography
                  sx={{ fontSize: "14px", fontFamily: `"Outfit",sans-serif` }}
                  className="navLabels"
                >
                  {course.title}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </>
    );
  };
  const renderCourseCategory = () => {
    return (
      <>
        <Box
          onMouseLeave={() => setFilteredCourses([])}
          sx={{ p: { xs: "30px 16px", md: "31px 35px 61px" } }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Outfit, sans-serif",
              fontSize: { md: "20px", xs: "16px" },
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              marginBottom: "8px",
            }}
          >
            {/* Course categories */}
          </Typography>
          <Typography
            sx={{
              color: "#868686",
              fontFamily: "Outfit, sans-serif",
              fontSize: "13px",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Our programs are designed to provide a focused and meaningful
            learning experience.
          </Typography>
          <Box
            sx={{ display: "flex" }}
            onMouseLeave={() => setFilteredCourses([])}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                m: { md: "30px 0 10px 0", xs: "42px" },
              }}
            >
              {courses.map((course, index) => (
                <Typography
                  key={index}
                  className="navbarCourseCategory"
                  sx={{
                    p: { md: "13px 10px" },
                    mb: { md: "10px" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontFamily: "Outfit, sans-serif",
                  }}
                  onClick={(e) => {
                    onClickHandler(e, course.path);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "26px",
                    }}
                  >
                    <Box
                      component="img"
                      src={course.imageSource}
                      sx={{ height: "24px", width: "24px" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Outfit, sans-serif",
                      }}
                      className="navLabels"
                    >
                      {course.title}
                    </Typography>
                  </Box>
                  {/* <Box
                    component="img"
                    src={ArrowNavbar}
                    onClick={() => handleFilter(course.title)}
                    sx={{ transform: "rotate(-90deg)" }}
                  /> */}
                </Typography>
              ))}
            </Box>
            {/* <hr style={{ margin: "14px 24px", border: "1px solid #F0F0F0" }} /> */}
            {/* {renderSubCourses()} */}
          </Box>
        </Box>
      </>
    );
  };
  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: "#fff", height: { xs: "50px", md: "70px" } }}
      className="navbarWrapper"
    >
      <Container>
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              gap: { md: "30%" },
            }}
          >
            <Box
              component="img"
              src={horizontalLogo}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={(e) => {
                onClickHandler(e, "/");
              }}
            />

            <Box
              aria-describedby={id}
              onMouseOver={(e) => setAnchorElCourses(e.currentTarget)}
              className="coursesButton"
              sx={{
                my: 0,
                color: "#7234F7 !important",
                display: "flex",
                alignItems: "center",
                width: "auto",
              }}
            >
              Courses
              {open ? (
                <Box
                  component="img"
                  src={upArrow}
                  sx={{ width: "40px", p: "14px", mt: "2px" }}
                />
              ) : (
                <Box
                  component="img"
                  src={dropArrow}
                  sx={{ width: "40px", transform: "rotate(-90deg)" }}
                />
              )}
            </Box>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorElCourses}
              onClose={() => setAnchorElCourses(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              PaperProps={{
                onMouseLeave: () => setAnchorElCourses(null),
                style: {
                  width: "55%",
                  borderRadius: "24px",
                  backgroundColor: "#FFF",
                  marginTop: "16px",
                  boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.08)",
                  minHeight: "380px",
                  fontFamily: `"Outfit", sans-serif !important`,
                },
              }}
            >
              {renderCourseCategory()}
            </Popover>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              maxWidth: "fit-content",
            }}
          >
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ p: "0" }}
            >
              <Box component="img" src={MenuIcon} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                onClick={(e) => {
                  onClickHandler(e, "/findyourcareer");
                  handleCloseNavMenu();
                }}
                className="navLabels"
              >
                <Typography textAlign="center">Find Your Career</Typography>
              </MenuItem>
              {/* <MenuItem
                key={"internvita_projects_mobile"}
                onClick={(e) => {
                  window.open(
                    "https://abrupt-class-af8.notion.site/Internvita-Project-List-1ffd698d12ac43ee9c4493c5584a181e",
                    "_blank"
                  );
                  handleCloseNavMenu();
                }}
                className="navLabels"
              >
                <Typography textAlign="center">Internvita</Typography>
              </MenuItem> */}
              {/* <MenuItem
                  key={"internvita_projects_mobile"}
                  onClick={(e) => {
                    window.open('https://workshops.coursevita.com/looker-studio-cohort', '_blank');
                  key={"internvita_projects_mobile"}
                  onClick={(e) => {
                    window.open('https://abrupt-class-af8.notion.site/Internvita-Project-List-1ffd698d12ac43ee9c4493c5584a181e', '_blank');
                    handleCloseNavMenu();
                  }}
                  className="navLabels"
                >
                  <Typography textAlign="center">Google Looker Studio Cohort</Typography>
                </MenuItem> */}
              {/* </MenuItem> */}
              {/* //-------------Mobile dropDown--------------// */}
              <MenuItem
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5px",
                  color: isDropdownMobileVisible ? "black" : "#FE7465",
                }}
                onClick={() => {
                  setIsDropdownMobileVisible(!isDropdownMobileVisible);
                }}
              >
                Skill Up{" "}
                {isDropdownMobileVisible ? (
                  <Box component="img" src={downArrow}></Box>
                ) : (
                  <Box component="img" src={leftArrow}></Box>
                )}
              </MenuItem>
              {isDropdownMobileVisible && (
                <>
                  <MenuItem
                    className="dropdownList-mobile"
                    onClick={() => {
                      navigate("/projects");
                      setIsDropdownMobileVisible(false);
                    }}
                  >
                    Projects
                  </MenuItem>
                  {/* <MenuItem
                    className="dropdownList-mobile"
                    onClick={() => {
                      navigate("/careerCounselling");
                      setIsDropdownMobileVisible(false);
                    }}
                  >
                    Mock Interview
                  </MenuItem> */}
                  {/* <MenuItem
                    className="dropdownList-mobile"
                    onClick={() => {
                      navigate("/mockGroupDiscussion");
                      setIsDropdownMobileVisible(false);
                    }}
                  >
                    Prepare for GD
                  </MenuItem> */}
                  <MenuItem
                    className="dropdownList-mobile"
                    onClick={() => {
                      window.open(
                        "https://abrupt-class-af8.notion.site/Internvita-Project-List-1ffd698d12ac43ee9c4493c5584a181e",
                        "_blank"
                      );
                      handleCloseNavMenu();
                      setIsDropdownMobileVisible(false);
                    }}
                  >
                    Internvita
                  </MenuItem>
                </>
              )}
              {/* //-------------------------// */}
              {mobilePages.map((page, index) => (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    onClickHandler(e, page.path);
                    handleCloseNavMenu();
                  }}
                  className="navLabels"
                >
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
              <MenuItem
                key={"internvita_projects_mobile"}
                onClick={(e) => {
                  window.open(
                    "https://upskill.coursevita.com/aboutus",
                    "_blank"
                  );
                  handleCloseNavMenu();
                }}
                className="navLabels"
              >
                <Typography textAlign="center">About Us</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              component="img"
              src={horizontalLogo}
              sx={{
                height: "22px",
                ml: "20px",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={(e) => {
                onClickHandler(e, "/");
              }}
            />
            <Button
              className="coursesButton courseBtnSmall"
              onClick={toggleDrawer(true)}
            >
              Courses
            </Button>
            <Drawer
              anchor="right"
              open={openCourse}
              onClose={toggleDrawer(false)}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{ display: { xs: "block", md: "none" } }}
              PaperProps={{
                style: { width: "100%", height: "100%" },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: 2,
                }}
                role="presentation"
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    onClick={toggleDrawer(false)}
                    sx={{ p: "0", maxWidth: "fit-content", minWidth: "0" }}
                  >
                    <Box component="img" src={closeArrow} />
                  </Button>
                  <Typography className="drawerTitle">Courses</Typography>
                </Box>
                <Box sx={{ p: { xs: "30px 0", md: "31px 35px 61px" } }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Outfit, sans-serif",
                      fontSize: { md: "20px", xs: "16px" },
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                      marginBottom: "8px",
                    }}
                  >
                    Course categories
                  </Typography>
                  <Typography
                    sx={{
                      color: "#868686",
                      fontFamily: "Outfit, sans-serif",
                      fontSize: "13px",
                      fontWeight: "400",
                      lineHeight: "normal",
                      mb: "42px",
                    }}
                  >
                    Our programs are designed to provide a focused and
                    meaningful learning experience.
                  </Typography>
                  {courses?.map((course, index) => (
                    <Box key={index}>
                      {course && course.courseHeader ? (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#7234F7",
                            mb: "10px",
                          }}
                        >
                          {course.courseHeader}
                        </Typography>
                      ) : (
                        ""
                      )}

                      <Box
                        className="navbarCourseCategory"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "26px",
                          mb: "10px",
                          p: "8px",
                        }}
                        onClick={(e) => {
                          onClickHandler(e, course.path);
                          toggleDrawer(true);
                        }}
                      >
                        <Box
                          component="img"
                          src={course.imageSource}
                          sx={{ height: "24px", width: "24px" }}
                        />
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontFamily: `"Outfit", sans-serif`,
                          }}
                        >
                          {course.title}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                  {/* {courseCategories.map((course, index) => (
                    <Accordion
                      key={index}
                      className="navbarCourseCategory"
                      PaperProps={{
                        style: {
                          border: "none",
                        },
                      }}
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                      onClick={() => handleFilter(course.title)}
                    >
                      <AccordionSummary
                        expandIcon={
                          <Box
                            component="img"
                            sx={{
                              height: { xs: "24px", md: "36px" },
                              mb: "9px",
                            }}
                            src={ArrowNavbar}
                            alt="expand icon"
                            onClick={() => handleFilter(course.title)}
                          />
                        }
                        aria-controls="panel1a-content"
                        sx={{
                          fontFamily: `"Poppins", sans-serif`,
                          cursor: "pointer",
                          // height: "36px",
                          // padding: { xs: "0px 10px", sm: "0px 0px" },
                          fontSize: { xs: "12px", md: "16px" },
                          fontWeight: "400",
                          borderBottom: "none",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "26px",
                          }}
                        >
                          <Box
                            component="img"
                            src={course.categoryImage}
                            sx={{ height: "24px", width: "24px" }}
                          />
                          <Typography sx={{ fontSize: "14px" }}>
                            {course.title}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          fontFamily: `"Outfit", sans-serif`,
                          fontSize: { xs: "12px", md: "16px" },
                          fontWeight: "400",
                          lineHeight: "1.75",
                          letterSpacing: "normal",
                          textAlign: "left",
                          p: "0",
                        }}
                      >
                        <Box
                          sx={{
                            width: { xs: "100%", md: "50%" },
                            m: { md: "25px 0 10px 0", xs: "16px" },
                            borderRadius: "13px",
                          }}
                        >
                          {filteredCourses?.map((course, index) => (
                            <Box key={index}>
                              {course && course.courseHeader ? (
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    color: "#7234F7",
                                    mb: "10px",
                                  }}
                                >
                                  {course.courseHeader}
                                </Typography>
                              ) : (
                                ""
                              )}

                              <Box
                                className="navbarCourseCategory"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "26px",
                                  mb: "10px",
                                  p: "8px",
                                }}
                                onClick={(e) => {
                                  onClickHandler(e, course.path);
                                  toggleDrawer(true);
                                }}
                              >
                                <Box
                                  component="img"
                                  src={course.imageSource}
                                  sx={{ height: "24px", width: "24px" }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontFamily: `"Outfit", sans-serif`,
                                  }}
                                >
                                  {course.title}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))} */}
                  <Box />
                </Box>
              </Box>
            </Drawer>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {/* <Button
                  key={"internvita_projects"}
                  onClick={(e) => {
                    window.open('https://workshops.coursevita.com/looker-studio-cohort', '_blank');
                    handleCloseNavMenu();
                  }}
                  // className={
                  //   currentPath === page.path
                  //     ? "navLabels navSelected"
                  //     : "navLabels"
                  // }
                  sx={{
                    my: 2,
                    color: "rgba(132, 132, 132, 1)",
                    display: "block",
                    textTransform: "none",
                    fontFamily: `"Outfit", sans-serif`,
                    mr: "30px",
                  }}
                >
                  Google Looker Studio Cohort
                </Button> */}

              <Button
                onClick={(e) => {
                  onClickHandler(e, "/findyourcareer");
                  handleCloseNavMenu();
                }}
                className={
                  currentPath === "/findyourcareer"
                    ? "navLabels navSelected"
                    : "navLabels"
                }
                sx={{
                  my: 2,
                  color: "rgba(132, 132, 132, 1)",
                  display: "block",
                  textTransform: "none",
                  fontFamily: `"Outfit", sans-serif`,
                  mr: "30px",
                }}
              >
                Find Your Career
              </Button>

              {/* //--------------Drop-Down-----------// */}
              <Box position="relative">
                <Button
                  onClick={() => {
                    setIsDropdownDesktopVisible(!isDropdownDesktopVisible);
                  }}
                  key={"internvita_projects"}
                  sx={{
                    my: 2,
                    color: isDropdownDesktopVisible
                      ? "rgba(132, 132, 132, 1)"
                      : "#FE7465",
                    display: "block",
                    textTransform: "none",
                    fontFamily: `"Outfit", sans-serif`,
                    mr: "30px",
                  }}
                >
                  Skill Up{" "}
                  {isDropdownDesktopVisible ? (
                    <Box component="img" src={downArrow}></Box>
                  ) : (
                    <Box component="img" src={leftArrow}></Box>
                  )}
                </Button>
                {isDropdownDesktopVisible && (
                  <Box className="dropdown-navbar-desktop">
                    <Button
                      onClick={() => {
                        navigate("/projects");
                        setIsDropdownDesktopVisible(false);
                      }}
                      className="dropdownList-desktop"
                    >
                      Projects
                    </Button>
                    {/* <Button
                      onClick={() => {
                        navigate("/careerCounselling");
                        setIsDropdownDesktopVisible(false);
                      }}
                      className="dropdownList-desktop"
                    >
                      Mock Interview
                    </Button> */}
                    {/* <Button
                      onClick={() => {
                        navigate("/mockGroupDiscussion");
                        setIsDropdownDesktopVisible(false);
                      }}
                      className="dropdownList-desktop"
                    >
                      Prepare for GD
                    </Button> */}
                    <Button
                      key={"internvita_projects"}
                      onClick={(e) => {
                        window.open(
                          "https://abrupt-class-af8.notion.site/Internvita-Project-List-1ffd698d12ac43ee9c4493c5584a181e",
                          "_blank"
                        );
                        handleCloseNavMenu();
                        setIsDropdownDesktopVisible(false);
                      }}
                      className="dropdownList-desktop"
                    >
                      Internvita
                    </Button>
                  </Box>
                )}
              </Box>
              {/* //-----------------------// */}

              {pages.map((page, index) => (
                <Button
                  key={index}
                  onClick={(e) => {
                    onClickHandler(e, page.path);
                    handleCloseNavMenu();
                  }}
                  className={
                    currentPath === page.path
                      ? "navLabels navSelected"
                      : "navLabels"
                  }
                  sx={{
                    my: 2,
                    color: "rgba(132, 132, 132, 1)",
                    display: "block",
                    textTransform: "none",
                    fontFamily: `"Outfit", sans-serif`,
                    mr: "30px",
                  }}
                >
                  {page.title}
                </Button>
              ))}
              <Button
                key={"internvita_projects"}
                onClick={(e) => {
                  window.open(
                    "https://upskill.coursevita.com/aboutus",
                    "_blank"
                  );
                  handleCloseNavMenu();
                }}
                // className={
                //   currentPath === page.path
                //     ? "navLabels navSelected"
                //     : "navLabels"
                // }
                sx={{
                  my: 2,
                  color: "rgba(132, 132, 132, 1)",
                  display: "block",
                  textTransform: "none",
                  fontFamily: `"Outfit", sans-serif`,
                  mr: "30px",
                }}
              >
                About Us
              </Button>
              {/* <Button
                onClick={(e) => {
                  onClickHandler(e, "/register");
                  handleCloseNavMenu();
                }}
                sx={{
                  my: 2,
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "normal",
                  color: "#7234F7",
                  display: "block",
                  textTransform: "none",
                  borderRadius: "4px",
                  border: "1px solid #7234F7",
                  mr: "20px",
                  fontFamily: `"Outfit", sans-serif`,
                  p: "10px 24px",
                }}
              >
                Register
              </Button>
              <Button
                onClick={(e) => {
                  onClickHandler(e, "/login");
                  handleCloseNavMenu();
                }}
                sx={{
                  my: 2,
                  color: "rgba(132, 132, 132, 1)",
                  display: "block",
                  textTransform: "none",
                  fontFamily: `"Outfit", sans-serif`,
                }}
              >
                Login
              </Button> */}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
