import "./TopLayer.scss";
import { Box, Button, Typography } from "@mui/material";
import heroImage from "../../../assets/images/mockGroupDiscussion/MockGroupHeroImage.png";
const TopLayer = () => {
  return (
    <Box position="relative" className="hero-container">
      <Box className="hero-image-top" component="img" src={heroImage}></Box>
      <Box className="header-text-wrapper-top">
        <Box className="header-text-subwrapper-top">
          <Typography className="header-text-1-top">Join Our</Typography>
          <Typography className="header-text-2-top">
            Mock Group Discussions
          </Typography>
          <Typography className="header-text-3-top">
            Speak Up, Fear Less!
          </Typography>
        </Box>
        <Typography className="normal-text-top">
          Discover the joy of sharing your thoughts and overcoming speaking
          anxiety.
        </Typography>
        <Button className="btn-top">Join Now</Button>
      </Box>
      <Box className="gradient-bg"></Box>
    </Box>
  );
};

export default TopLayer;
