import neha from "./neha.jpg";
import nikanksha from "./nikansha.jpg";
import revanth from "./revanth.JPG";
import Ejaz from "./Ejaz.jpg";
import linkedin from "./linkedin.svg";
import defaultImage from "./default.jpg";

const mentorImages = {
  "neha": neha,
  "nikanksha": nikanksha,
  "revanth": revanth,
  "Ejaz": Ejaz,
  "linkedin": linkedin,
  "default": defaultImage,
};

export default mentorImages;
