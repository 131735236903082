import "../questions/Questions.scss";
import arrowDropDown from "../../../assets/images/arrowDropdown.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
const Questions = ({courseContent}) => {
  
  
  return (
   <Box  className="questionsSection" sx={{p:{md:"60px 16px 50px 16px" ,xs:"10px 16px"}}}>
  <Typography className="title" sx={{mb:"30px" , fontSize:{md:"36px", xs:"20px"}}}>Frequently asked questions</Typography>
     <Box sx={{ display: "flex",flexDirection:"column", m:"auto",alignItems: "center"}} >
      {courseContent?.faqs?.map((item, index) => {
        return (
          <Accordion
            elevation={0}
            sx={{
              border: "1px #A3A3A3 solid",
              borderRadius: "10px",
              padding: "16px 26px",
              width:"100%",
              marginBottom: {xs:"10px",md:"16px"},
              maxWidth: "1000px",
            }}
          >
            <AccordionSummary
              expandIcon={<Box component="img" sx={{height:{xs:"24px",md:"36px"},mb:"9px"}} src={arrowDropDown} alt="expand icon" />}
              aria-controls="panel1a-content"
              sx={{
                fontFamily: `"Poppins", sans-serif`,
                cursor: "pointer",
                height: "36px",
                padding: { xs: "0px 10px", sm: "0px 0px" },
                fontSize: {xs:"12px",md:"16px"},
                fontWeight: "600",
              }}
            >
              {item.question}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                fontFamily: `"Outfit", sans-serif`,
                fontSize: {xs:"12px",md:"16px"},
                fontWeight: "400",
                lineHeight: "1.75",
                letterSpacing: "normal",
                textAlign: "left",
              }}
            >
              {item.answer}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
   </Box>
  );
};
export default Questions;
