import Companies from "./companies/Companies";
import CoursesSection from "./coursesSection/CoursesSection";
// import Discover from "./discover/Discover";
import WhyCoursevita from "./whyCoursevita/WhyCoursevita";
import LearningJourney from "./learningJourney/LearningJourney";
import Testimonials from "./testimonials/Testimonials";
import Subscribe from "./subscribe/Subscribe";
import BookYourDemo from "./bookYourDemo/BookYourDemo";
import ExploreSection from "./exploreSection/ExploreSection";
import Footer from "../commonComponents/footer/Footer";
import Navbar from "../commonComponents/navbar/Navbar";
import { Box } from "@mui/material";
import Workshops from "./workshops/Workshops";

const LandingPage = (props) => {
  return (
    <Box sx={{ backgroundColor: "#f3f4fd" }}>
      <Navbar />
      <ExploreSection />
      <Workshops />
      <Companies />
      <CoursesSection coursesContent={props.coursesContent} />
      <WhyCoursevita />
      <LearningJourney />
      <Testimonials />
      <Subscribe />
      <BookYourDemo />
      <Footer />
    </Box>
  );
};

export default LandingPage;
