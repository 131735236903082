import { Box, Typography, Button } from "@mui/material";
import { useState } from "react";
import "../learningJourney/LearningJourney.scss";
import presentationIcon from "../../../assets/images/learningJourney/presentation.svg";
import presentationIconDark from "../../../assets/images/learningJourney/presentationdark.svg";
import targetIcon from "../../../assets/images/learningJourney/target.svg";
import targetIconDark from "../../../assets/images/learningJourney/targetdark.svg";
import technicalIcon from "../../../assets/images/learningJourney/technical.svg";
import technicalLightIcon from "../../../assets/images/learningJourney/technicallight.svg";
import closedQuote from "../../../assets/images/learningJourney/backQuote.svg";
import openQuote from "../../../assets/images/learningJourney/frontQuote.svg";
import arrow from "../../../assets/images/learningJourney/arrow.svg";
import Rectangle from "../../../assets/images/learningJourney/Rectangle.png";
import imageFrame1 from "../../../assets/images/learningJourney/imageFrame1.png";
import imageFrame2 from "../../../assets/images/learningJourney/imageFrame2.png";
import imageFrame3 from "../../../assets/images/learningJourney/imageFrame3.png";

const LearningJourney = () => {
  const [selectedContent, setSelectedContent] = useState("projectExperience");

  const content = {
    projectExperience: {
      text: '<span style="font-weight: 700;">We empower</span> each student with hands-on project experience to build practical skills, knowledge, and confidence to thrive in a dynamic world.',
      image: imageFrame1,
    },
    careerCounselling: {
      text: "Our career counselling sessions help students navigate their career paths with confidence.",
      image: imageFrame2,
    },
    selfPacedLearning: {
      text: "We offer self-paced learning modules for students to learn at their own speed.",
      image: imageFrame3,
    },
  };

  const handleButtonClick = (contentKey) => {
    setSelectedContent(contentKey);
  };

  return (
    <Box
      className="learningJourney"
      sx={{ p: { xs: "30px 16px", md: "40px 86px" } }}
    >
      <Box className="headerWrapper" sx={{ mb: "48px" }}>
        <Typography
          className="headText"
          sx={{ fontWeight: "400", fontSize: { xs: "20px", md: "36px" } }}
        >
          Why are we
        </Typography>
        <Typography
          className="subText"
          sx={{
            fontWeight: "700",
            fontSize: { xs: "20px", md: "36px" },
            "& span": { fontWeight: "500", color: "#FE7465" },
          }}
        >
          Best part of <span>your learning journey?</span>
        </Typography>
      </Box>
      <Box
        className="buttonWrapper"
        sx={{ flexDirection: { xs: "column", md: "row" },gap:"8px" }}
      >
        <Button
          sx={{
            py: "10px",
            borderRadius:{md:"12px",xs:"6px"},
            backgroundColor:
              selectedContent === "projectExperience" ? "#000000" : "#FFFFFF",
            color:
              selectedContent === "projectExperience" ? "#FFFFFF" : "#000000",
            maxWidth: { md: "323px" },
            width: { md: "30%" },
            fontSize: { xs: "16px", md: "20px" },
            textTransform:"none"
          }}
          className={
            selectedContent === "projectExperience"
              ? "journeyButton noJourneyButton"
              : "journeyButton"
          }
          onClick={() => handleButtonClick("projectExperience")}
          startIcon={
            <Box
              component="img"
              sx={{ width: { xs: "24px !important", md: "48px !important" } }}
              src={
                selectedContent === "projectExperience"
                  ? presentationIcon
                  : presentationIconDark
              }
              alt="icon"
            />
          }
        >
          Project Experience
        </Button>{" "}
        <Button
          sx={{
            py: "10px",
            borderRadius:{md:"12px",xs:"6px"},
            backgroundColor:
              selectedContent === "careerCounselling" ? "#000000" : "#FFFFFF",
            color:
              selectedContent === "careerCounselling" ? "#FFFFFF" : "#000000",
            width: { md: "30%" },
            maxWidth: { md: "323px" },
            fontSize: { xs: "16px", md: "20px" },
          }}
          className={
            selectedContent === "careerCounselling"
              ? "journeyButton noJourneyButton"
              : "journeyButton"
          }
          onClick={() => handleButtonClick("careerCounselling")}
          startIcon={
            <Box
            component="img"
            sx={{ width: { xs: "24px !important", md: "48px !important" } }}
              src={
                selectedContent === "careerCounselling"
                  ? technicalLightIcon
                  : technicalIcon
              }
              alt="icon"
            />
          }
        >
          Career Counselling
        </Button>{" "}
        <Button
          sx={{
            py: "10px",
            borderRadius:{md:"12px",xs:"6px"},
            backgroundColor:
              selectedContent === "selfPacedLearning" ? "#000000" : "#FFFFFF",
            color:
              selectedContent === "selfPacedLearning" ? "#FFFFFF" : "#000000",
            width: { md: "30%" },
            maxWidth: { md: "323px" },
            fontSize: { xs: "16px", md: "20px" },
          }}
          className={
            selectedContent === "selfPacedLearning"
              ? "journeyButton noJourneyButton"
              : "journeyButton"
          }
          onClick={() => handleButtonClick("selfPacedLearning")}
          startIcon={ 
            <Box
            component="img"
            sx={{ width: { xs: "24px !important", md: "48px !important" } }}
              src={
                selectedContent === "selfPacedLearning"
                  ? targetIcon
                  : targetIconDark
              }
              alt="icon"
            />
          }
        >
          Self-paced Learning
        </Button>
      </Box>
      <Box
        className="frameWrapper"
        sx={{ position: "relative", overflow: "hidden",mt:{md:"50px",xs:"16px"}  ,borderRadius:{md:"20px",xs:"12px"}}}
      >
        <Box
          component="img"
          src={openQuote}
          sx={{
            position: "absolute",
            top: "0px",
            left: "50px",
            width: "64px",
            height: "123px",
            visibility: { xs: "hidden", md: "visible" },
          }}
        />
        <Box
          component="img"
          src={closedQuote}
          sx={{
            position: "absolute",
            bottom: "0px",
            right: "50%",
            width: "64px",
            height: "140px",
            visibility: { xs: "hidden", md: "visible" },
          }}
        />
        <Box
          component="img"
          src={arrow}
          sx={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            visibility: { xs: "hidden", md: "visible" },
          }}
        />
        <Box
          className="frameContent"
          sx={{
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { md: "space-between" },
            width: "100%",
            zIndex: 1,
          }}
        >
          <Box
            className="leftDiv"
            sx={{
              position: "relative",
              p: "26px 20px",
              height: "100%",
              width: { xs: "100%", md: "55%" },
              alignSelf: "center",
            }}
          >
            <Typography
              sx={{ p: {md:"80px 20px",xs:"35px 20px"}, fontSize: { xs: "14px", md: "20px" } }}
              className="description"
              dangerouslySetInnerHTML={{
                __html: content[selectedContent].text,
              }}
            />
          </Box>
          <Box
            className="rightDiv"
            sx={{
              position: "relative",
              zIndex: 2,
              width: { xs: "100%", md: "45%" },
              overflow: "hidden",
            }}
          >
            <Box
              component="img"
              src={content[selectedContent].image}
              sx={{ width: "100%", p: "26px 20px" }}
            />
            <Box
              component="img"
              src={Rectangle}
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                height: "100%",
                zIndex: -1,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LearningJourney;
