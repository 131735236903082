import arrow from "../../../assets/images/ProjectsPage/arrow.svg";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./types.scss";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CircleArrow from "../../../assets/images/ProjectsPage/circlearrow.svg";
import Courses1 from "../../../assets/images/ProjectsPage/courses1.svg";
import Courses2 from "../../../assets/images/ProjectsPage/courses2.svg";
import Courses3 from "../../../assets/images/ProjectsPage/courses3.svg";
import Courses4 from "../../../assets/images/ProjectsPage/courses4.svg";
import Courses5 from "../../../assets/images/ProjectsPage/courses5.svg";
import Courses6 from "../../../assets/images/ProjectsPage/courses6.svg";

const data = [
  {
    id: 1,
    title: "Data Analytics",
    image: Courses1,
    link: "/projects/Data_Analytics_Projects",
  },
  {
    id: 2,
    title: "Data Science",
    image: Courses2,
    link: "/projects/Data_Science_Projects",
  },
  {
    id: 3,
    title: "Data Visualisation",
    image: Courses3,
    link: "/projects/Data_Visualization_Projects",
  },
  {
    id: 4,
    title: "Data Engineering",
    image: Courses4,
    link: "/projects/Data_Engineering_Projects",
  },
  {
    id: 5,
    title: "Devops",
    image: Courses5,
    link: "/projects/Devops_Projects",
  },
  {
    id: 6,
    title: "UX/UI Design",
    image: Courses6,
    link: "/projects/Ux_Ui_Design_Projects",
  },
];

const Types = () => {
  const [index, setIndex] = useState(0);
  const itemsPerPage = 4;
  const maxSteps = Math.ceil(data.length / itemsPerPage);
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNext = () => {
    if (index + itemsPerPage < data.length) {
      setIndex(index + itemsPerPage);
    }
  };
  const handleImageClick = (link) => {
    navigate(link);
  };

  const handlePrevious = () => {
    if (index - itemsPerPage >= 0) {
      setIndex(index - itemsPerPage);
    }
  };

  const currentItems = isXs ? data : data.slice(index, index + itemsPerPage);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            padding: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontFamily: "Outfit",
              fontSize: { xs: "30px", md: "40px" },
            }}
          >
            Explore
            <span
              style={{
                color: "#FE7465",
                marginLeft: "10px",
              }}
            >
              Projects
            </span>
          </Typography>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: { xs: "15px", md: "20px" },
              width: "100%",
            }}
          >
            Find interesting projects and get started with an execution plan
          </Typography>
        </Box>

        <Box
          sx={{
            marginTop: "25px",
            display: "grid",
            gridTemplateColumns: { xs: "repeat(2, 1fr)", md: "repeat(4, 1fr)" },
            gap: { xs: 3, md: 1 },
            alignSelf: "center",
            width: { xs: "90%", md: "70%" },
          }}
        >
          {currentItems.map((item) => (
            <Box
              key={item.id}
              sx={{
                padding: "16px",
                backgroundColor: "white",
                borderRadius: "8px",
                width: "90%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  src={item.image}
                  alt={item.title}
                  style={{ width: "60%" }}
                />
              </Box>
              <Typography
                sx={{
                  marginTop: "20px",
                  fontWeight: "500",
                  fontFamily: "Outfit",
                  fontSize: { xs: "15px", md: "15px" },
                  textAlign: "left",
                }}
              >
                {item.title}
              </Typography>

              <img
                style={{
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  marginTop: "30px",
                  justifyContent: "flex-start",
                  alignSelf: "flex-start",
                  cursor: "pointer",
                }}
                src={CircleArrow}
                onClick={() => handleImageClick(item.link)}
                alt="arrow"
              />
            </Box>
          ))}
        </Box>

        {!isXs && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px",
                width: "20%",
              }}
            >
              <Button disabled={index === 0} onClick={handlePrevious}>
                <Box
                  component="img"
                  sx={{ transform: "rotate(180deg)" }}
                  src={arrow}
                  size="small"
                />
              </Button>

              <Box
                sx={{
                  flexGrow: 1,
                  mx: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: 8,
                    backgroundColor: "#e0e0e0",
                    borderRadius: "12px",
                    position: "relative",
                    margin: "0 8px",
                  }}
                >
                  <Box
                    sx={{
                      width: `${100 / maxSteps}%`,
                      height: "100%",
                      position: "absolute",
                      backgroundColor: "#000000",
                      borderRadius: 12,
                      left: `${(100 / maxSteps) * (index / itemsPerPage)}%`,
                      transition: "left 0.3s",
                    }}
                  />
                </Box>
              </Box>

              <Button
                disabled={index + itemsPerPage >= data.length}
                onClick={handleNext}
              >
                <Box component="img" src={arrow} size="small" />
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Types;
