import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import leftIcon from "../../assets/images/blogPosts/leftIcon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../blog/blogPosts/BlogPosts.scss"

function BlogsPreview({ blogsContent }) {
  const { pathname } = useLocation();
  const { blogPreview } = useParams();
  const navigate = useNavigate();
  const [htmlContent, setHtmlContent] = useState("");
  const [suggestedData, setSuggestedData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const decodedBlogTitle = decodeURIComponent(blogPreview);
  const blogContent = blogsContent.find(
    (blog) => blog.title === decodedBlogTitle
  );

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch(blogContent.fullBlog);
        const text = await response.text();
        setHtmlContent(text);
      } catch (error) {
        console.error("Error fetching HTML content:", error);
      }
    };

    fetchHtmlContent();
  }, [blogContent]);

  useEffect(() => {
    setSuggestedData(
      blogsContent.filter((item) => item !== blogContent).slice(0, 3)
    );
  }, [blogsContent, blogContent]);

  const onClickHandler = (event, navigationPath) => {
    event.preventDefault();
    navigate(navigationPath);
  };

  const handleReadMore = (selected) => {
    navigate(`/blogs/${encodeURIComponent(selected.title)}`);
  };

  const truncate = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <>
      <Box
        className="blogContentWrapper"
        sx={{ m: { md: "36px 120px", xs: "24px 16px" }, color:"#000", backgroundColor:"#fff" }}
      >
        <Typography
          className="blogHeader"
          sx={{
            fontSize: { md: "16px", xs: "14px" },
            fontFamily: "Outfit, sans-serif",
          }}
        >
          <span
            className="blogHeaderContent"
            onClick={(e) => onClickHandler(e, "/")}
          >
            Home
          </span>
          &gt;&gt;
          <span
            className="blogHeaderContent"
            onClick={(e) => onClickHandler(e, "/blogs")}
          >
            Blogs
          </span>
          &gt;&gt;
          {blogContent.title}
        </Typography>
        <Typography className="blogHeaderTech">
          {blogContent.technology}
        </Typography>
        <Box
          className="blogContent"
          sx={{ p: { md: "32px 70px", xs: "24px" } }}
        >
          <Typography className="blogContentTitle">
            {blogContent.title}
          </Typography>
          <Typography
            className="blogContentTitle"
            sx={{
              color: { md: "#0F002E", xs: "#FF7262" },
              fontSize: { xs: "14px", md: "16px" },
              pb: "6px",
            }}
          >
            {blogContent.subtitle}
          </Typography>
          <Typography
            className="blogContentDate"
            sx={{ mb: { md: "24px", xs: "18px" } }}
          >
            {blogContent.date}
          </Typography>

          <Typography className="content">{blogContent.blog}</Typography>
          <Box
            component="img"
            src={blogContent.image}
            sx={{
              width: "100%",
              m: { md: "24px 0", xs: "20px 0" },
              borderRadius: { xs: "8px", md: "12px" },
            }}
          />
          <Box
            className="dangerhtml"
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        </Box>
      </Box>
      <>
        {suggestedData?.length === 0 ? (
          <>New Blogs Coming soon</>
        ) : (
          <>
            <Typography
              className="header"
              sx={{
                fontSize: { xs: "24px", md: "36px" },
                lineHeight: { xs: "30px", md: "54px" },
                color: "#FF7262",
              }}
            >
              You may also like
            </Typography>
            <Typography
              className="subheader"
              sx={{ m: { md: 0, xs: "0 16px" } }}
            >
              Thoughtfully compiled information aimed at enriching your
              understanding and broadening your scope of knowledge
            </Typography>
            {suggestedData.map((item, index) => {
              return (
                <Box
                  className="blogWrapper"
                  sx={{
                    m: {
                      md: "26px auto 26px auto",
                      xs: "16px",
                    },
                    width: { md: "80%" },
                  }}
                >
                  <Box
                    key={index}
                    className="cardWrapper"
                    sx={{
                      flexDirection: { xs: "column-reverse", md: "row" },
                      p: { sm: "20px 26px", xs: "16px" },
                    }}
                  >
                    <Box
                      className="contentWrapper"
                      sx={{
                        width: { md: "65%", xs: "100%" },
                        pt: { xs: "16px", md: "0px" },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography
                          className="title"
                          sx={{
                            color: { md: "#0F002E", xs: "#FF7262" },
                            fontSize: { xs: "16px", md: "24px" },
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          sx={{
                            color: { md: "#0F002E", xs: "#FF7262" },
                            fontSize: { xs: "14px", md: "16px" },
                            pb: "6px",
                          }}
                        >
                          {item.subtitle}
                        </Typography>
                        <Typography className="date">{item.date}</Typography>
                        <Typography
                          className="blog"
                          sx={{ fontSize: { xs: "14px", md: "16px" } }}
                        >
                          {truncate(item.blog, 200)}
                        </Typography>
                      </Box>
                      <Button
                        className="readMoreBtn"
                        sx={{
                          alignSelf: { md: "flex-start", xs: "center" },
                          mt: { xs: "50px", md: "20px" },
                        }}
                        onClick={() => handleReadMore(item)}
                        endIcon={<img src={leftIcon} alt="Read More Icon" />}
                      >
                        Read full blog
                      </Button>
                    </Box>
                    <Box
                      component="img"
                      src={item.image}
                      sx={{
                        width: { md: "30%", xs: "100%" },
                        minWidth: { lg: "442px", md: "390px" },
                        maxHeight: "230px",
                        borderRadius: { xs: "12px", md: "20px" },
                        m: "auto",
                      }}
                    />
                  </Box>
                </Box>
              );
            })}
          </>
        )}
      </>
    </>
  );
}

export default BlogsPreview;
