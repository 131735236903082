import { Box, Typography, Button, Pagination, Paper } from "@mui/material";
import leftIcon from "../../../assets/images/blogPosts/leftIcon.svg";
import blogArrow from "../../../assets/images/blogPosts/blogArrow.svg";
import arrow from "../../../assets/images/testimonials/arrow.svg";
import { useEffect, useState } from "react";
import "../blogPosts/BlogPosts.scss";
// import { isEmpty } from "lodash";
import CourseCard from "../../landingPage/coursesSection/courseCard/CourseCard";
import { useNavigate } from "react-router-dom";

const BlogPosts = (props) => {
  const { coursesContent, blogsContent } = props;
  const navigate = useNavigate();

  const trendingData = blogsContent?.filter((blog) => blog.trending === true);
  const getUniqueTechnologies = (blogs) => {
    const technologies = blogs.map((blog) => blog.technology);
    const uniqueTechnologies = [...new Set(technologies)];
    return ["All", ...uniqueTechnologies];
  };
  const technologyCategories = getUniqueTechnologies(blogsContent);

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = trendingData?.length;
  const [activeCategory, setActiveCategory] = useState("All");

  const [filteredData, setFilteredData] = useState(blogsContent);
  const [currentPage, setCurrentPage] = useState(1); // Start pagination from page 1
  const [filteredCourses, setFilteredCourses] = useState([]);

  useEffect(() => {
    if (coursesContent) {
      setFilteredCourses(
        coursesContent
          .filter(
            (post) =>
              post.technology.toLowerCase() === activeCategory.toLowerCase()
          )
          .slice(0, 4)
      );
    }
  }, [coursesContent, activeCategory]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
    );
  };

  const handleFilter = (technology) => {
    setActiveCategory(technology);
    if (technology.toLowerCase() === "all") {
      setFilteredData(blogsContent);
      setFilteredCourses([]);
    } else {
      const filtered = blogsContent?.filter(
        (post) => post.technology.toLowerCase() === technology.toLowerCase()
      );
      setFilteredData(filtered);
      const filteredCourses = coursesContent
        ?.filter(
          (post) => post.technology.toLowerCase() === technology.toLowerCase()
        )
        .slice(0, 4);
      setFilteredCourses(filteredCourses);
    }
    setCurrentPage(1);
  };
  
  // const [activeStepCourse, setActiveStepCourse] = useState(0);

  // const handleNextCourse = () => {
  //   setActiveStepCourse((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  // };

  // const handleBackCourse = () => {
  //   setActiveStepCourse(
  //     (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
  //   );
  // };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleReadMore = (selected) => {
    navigate(`/blogs/${encodeURIComponent(selected.title)}`);
  };

  const cardsPerPage = 3;
  const totalPages = Math.ceil(filteredData?.length / cardsPerPage);
  const truncate = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <>
      <>
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            position: "relative",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              transition: "transform 0.3s ease-in-out",
              transform: `translateX(-${activeStep * 100}%)`,
              width: "100%",
            }}
          >
            {trendingData?.map((data, index) => (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  flexShrink: 0,
                  p: { xs: "0 16px", md: "0 32px" },
                }}
              >
                <Box
                  className="trendingBlogWrapper"
                  key={index}
                  sx={{
                    backgroundImage: `url(${data.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: {
                      lg: "65vh",
                      md: "55vh",
                      sm: "45vh",
                      xs: "25vh",
                    }, 
                    width: "100%",

                    borderRadius: { xs: "12px", md: "24px" },
                  }}
                >
                  <Box
                    className="trendingWrapper"
                    sx={{
                      m: { md: "28px", xs: "10px" },
                      p: { md: "20px", xs: "10px" },
                    }}
                  >
                    <Typography
                      className="trendingTitle"
                      sx={{
                        p: { md: "4px 16px", xs: "2px 8px" },
                        fontSize: { md: "16px", xs: "10px" },
                        borderRadius: { md: "8px", xs: "2px" },
                      }}
                    >
                      Trending
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { md: "24px", xs: "10px" },
                        mt: { md: "20px", xs: "8px" },
                      }}
                    >
                      {data.title}
                    </Typography>
                  </Box>
                  <Button
                    onClick={() => {
                      handleReadMore(data);
                    }}
                    className="trendingblogsImages"
                    sx={{
                      fontSize: { md: "20px", xs: "12px" },
                      m: { md: "32px 48px", xs: "12px 16px" },
                      p: { md: "9px 16px ", xs: "5px 5px" },
                    }}
                  >
                    Read Blog{" "}
                    <Box
                      component="img"
                      src={blogArrow}
                      sx={{ width: { xs: "10px", md: "20px" }, pl: "3px" }}
                    />
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              width: "100%",
              mt: { md: "20px" },
              display: "flex",
              alignItems: "center",
              maxWidth: "400px",
            }}
          >
            <Button disabled={activeStep === 0} onClick={handleBack}>
              <Box
                component="img"
                sx={{ transform: "rotate(180deg)" }}
                src={arrow}
                size="small"
              />
            </Button>
            <Box sx={{ flexGrow: 1, mx: 2 }}>
              <Box
                sx={{
                  width: "100%",
                  height: 8,
                  backgroundColor: "#e0e0e0",
                  borderRadius: "12px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: `${100 / maxSteps}%`,
                    height: "100%",
                    position: "absolute",
                    backgroundColor: "#000000",
                    borderRadius: 12,
                    left: `${(100 / maxSteps) * activeStep}%`,
                    transition: "left 0.3s",
                  }}
                />
              </Box>
            </Box>
            <Button disabled={activeStep === maxSteps - 1} onClick={handleNext}>
              <Box component="img" src={arrow} size="small" />
            </Button>
          </Box>
        </Box>
        <Box
          className="blogWrapper"
          sx={{
            m: {
              md: "26px auto 26px auto",
              xs: "16px",
            },
            width: { md: "80%" },
          }}
        >
          <Typography
            className="header"
            sx={{
              fontSize: { xs: "24px", md: "36px" },
              lineHeight: { xs: "30px", md: "54px" },
            }}
          >
            <span style={{ color: "#FF7262" }}>Explore</span> advanced concepts
            with our blogs
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: { md: "center" },
              marginBottom: "20px",
              overflow: "auto",
              mb: "0px",
              whiteSpace: "nowrap",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {technologyCategories?.map((tech, index) => (
              <Button
                key={index}
                sx={{
                  display: "flex",
                  flexShrink: "0",
                  marginRight: "10px",
                  marginBottom: "10px",
                  width: "auto",
                }}
                className={`categoryBtns ${
                  activeCategory === tech ? "contained" : "outlined"
                }`}
                onClick={() => handleFilter(tech)}
              >
                {tech}
              </Button>
            ))}
          </Box>
          {filteredData?.length === 0 ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                my: 4,
                fontSize: "20px",
                color: "#727272",
              }}
            >
              New Blogs Coming soon!!!
            </Typography>
          ) : (
            <>
              {filteredData
                ?.slice(
                  (currentPage - 1) * cardsPerPage,
                  currentPage * cardsPerPage
                )
                .map((item, index) => (
                  <Box
                    key={index}
                    className="cardWrapper"
                    sx={{
                      flexDirection: { xs: "column-reverse", md: "row" },
                      p: { sm: "20px 26px", xs: "16px" },
                    }}
                  >
                    <Box
                      className="contentWrapper"
                      sx={{
                        width: { md: "65%", xs: "100%" },
                        pt: { xs: "16px", md: "0px" },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography
                          className="title"
                          sx={{
                            color: { md: "#0F002E", xs: "#FF7262" },
                            fontSize: { xs: "16px", md: "24px" },
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          sx={{
                            color: { md: "#0F002E", xs: "#FF7262" },
                            fontSize: { xs: "14px", md: "16px" },
                            pb: "6px",
                            fontFamily:"Outfit"
                          }}
                        >
                          {item.subtitle}
                        </Typography>
                        <Typography className="date">{item.date}</Typography>
                        <Typography
                          className="blog"
                          sx={{ fontSize: { xs: "14px", md: "16px" } }}
                        >
                          {truncate(item.blog, 200)}
                        </Typography>
                      </Box>
                      <Button
                        className="readMoreBtn"
                        sx={{
                          alignSelf: { md: "flex-start", xs: "center" },
                          mt: { xs: "50px", md: "20px" },
                        }}
                        onClick={() => handleReadMore(item)}
                        endIcon={<img src={leftIcon} alt="Read More Icon" />}
                      >
                        Read full blog
                      </Button>
                    </Box>
                    <Box
                      component="img"
                      src={item.image}
                      sx={{
                        width: { md: "30%", xs: "100%" },
                        minWidth: { lg: "442px", md: "390px" },
                        maxHeight: "230px",
                        borderRadius: { xs: "12px", md: "20px" },
                      }}
                    />
                  </Box>
                ))}
              {totalPages > 1 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={handlePageChange}
                    sx={{
                      "& .Mui-selected": {
                        border: "1px #0F002E solid",
                        backgroundColor: "#FFFFFF !important",
                      },
                      "& .MuiPaginationItem-root": {
                        color: "#000000",
                      },
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </>
      {filteredCourses.length !== 0 && (
        <Box
          className="coursesSection"
          sx={{
            p: { md: "30px 80px 0px", xs: "20px 16px 20px 16px" },
            backgroundColor: "#F3F4FD",
          }}
        >
          <Box
            className="headersSection"
            sx={{ mb: { md: "5px", xs: "20px" } }}
          >
            <Typography
              className="headText"
              sx={{ fontSize: { md: "32px", xs: "20px" } }}
            >
              Explore
            </Typography>
            <Typography
              className="subText"
              sx={{ fontSize: { md: "36px", xs: "20px" } }}
            >
              Our {activeCategory} courses
            </Typography>
          </Box>
          <Box
            className="cardsWrapper"
            sx={{
              overflow: { xs: "auto", md: "hidden" },
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Box
              className="courseCards"
              sx={{
                width: "100%",
                // transition: "transform 0.3s ease",
                // transform: { md: `translateX(-${activeStepCourse * 45}%)` },
                p: { md: "40px 0 76px", xs: "16px 0" },
              }}
            >
              {filteredCourses?.map((courseContent, index) => (
                <CourseCard key={index} courseContent={courseContent} />
              ))}
            </Box>
          </Box>
          {/* {filteredCourses?.length > 2 && (
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  mb: { md: "60px" },
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "400px",
                }}
              >
                <Button
                  disabled={activeStepCourse === 0}
                  onClick={handleBackCourse}
                >
                  <Box
                    component="img"
                    sx={{ transform: "rotate(180deg)" }}
                    src={arrow}
                    size="small"
                  />
                </Button>
                <Box sx={{ flexGrow: 1, mx: 2 }}>
                  <Box
                    sx={{
                      width: "100%",
                      height: 8,
                      backgroundColor: "#e0e0e0",
                      borderRadius: "12px",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: `${100 / (maxStepsCourses - 1)}%`,
                        height: "100%",
                        position: "absolute",
                        backgroundColor: "#000000",
                        borderRadius: 12,
                        left: `${
                          (100 / (maxStepsCourses - 1)) * activeStepCourse
                        }%`,
                        transition: "left 0.3s",
                      }}
                    />
                  </Box>
                </Box>
                <Button
                  disabled={activeStepCourse >= maxStepsCourses - 2}
                  onClick={handleNextCourse}
                >
                  <Box component="img" src={arrow} size="small" />
                </Button>
              </Box>
            </Box>
          )} */}
        </Box>
      )}
    </>
  );
};

export default BlogPosts;
