import { Typography, Box } from "@mui/material";
import "../companies/Companies.scss";
import logo1 from "../../../assets/images/companies/logo1.png";
import logo2 from "../../../assets/images/companies/logo2.png";
import logo3 from "../../../assets/images/companies/logo3.jpg";
import logo4 from "../../../assets/images/companies/logo4.png";
import logo5 from "../../../assets/images/companies/logo5.png";
import logo6 from "../../../assets/images/companies/logo6.png";
const Companies = () => {
  const logos = [
    { logoImage: logo1 },
    { logoImage: logo2 },
    { logoImage: logo3 },
    { logoImage: logo4 },
    { logoImage: logo5 },
    { logoImage: logo6 },
  ];
  const logosClone = [...logos, ...logos, ...logos];
  return (
    <>
      {" "}
      <Box className="companiesSection" sx={{p:{md:"5px 0px 25px 0px",xs:"15px 0px 5px 0px"}}}>
        <Typography
          className="headText"
          sx={{
            fontSize: { xs: "20px", md: "36px" },
            px: "16px",
            lineHeight: { xs: "25px", md: "45px" },
          }}
        >
          <span style={{ color: "#FF7262" }}> Get placed</span> in leading MNC's
        </Typography>
        <Typography
          variant="body1"
          className="subText"
          sx={{ fontSize: { xs: "14px !important", md: "20px" }, px: "16px" }}
        >
          land your dream job in MAANG companies with
          <span style={{ fontWeight: "700" }}> coursevita’s </span> placement
          guidance
        </Typography>
        <Box className="companiesSlider" sx={{mt:{md:"24px",xs:"12px"}}}> 
          <Box className="innerSlider" >
            {logosClone.map((logo, index) => (
              <Box  key={index} className="logoContainer" sx={{m:{xs:" auto 13px" ,md:" auto 45px"},minWidth:{md:"130px",xs:"90px"}}}>
                <img src={logo.logoImage} alt="logo-img" />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Companies;
