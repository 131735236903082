import { Box, Link, Typography } from "@mui/material";
import "./CourseCard.scss";
import arrow from "../../../../assets/images/blueArrow.svg";
import calendar from "../../../../assets/images/calendarBlack.svg";
import greenDot from "../../../../assets/images/greenDot3.gif";

const CourseCard = ({ content }) => {
  return (
    <a
      style={{ textDecoration: "none" }}
      href={content?.Link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box className="courseCard">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box
            sx={{ borderRadius: "5px", width: "80px", height: "47px" }}
            component="img"
            src={content.image}
          ></Box>
          <Box
            display="flex"
            padding="10px"
            sx={{
              borderRadius: "36px",
              background: "#FFF",
              backdropFilter: "blur(32.54999923706055px)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{ width: "12px", height: "12px" }}
              src={greenDot}
            ></Box>
            <Typography
              sx={{
                color: "var(--Filled, #000)",
                fontFamily: "Outfit",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",

              }}
            >
              Upcoming
            </Typography>
          </Box>
        </Box>
        <Typography className="text-sliderCard-1-landingPage">
          {content.tag1}
        </Typography>
        <Typography className="text-sliderCard-2-landingPage">
          {content.boldTag}
        </Typography>
        <Box display="flex">
          <Box component="img" src={arrow}></Box>
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            width="100%"
            gap="5px"
          >
            <Box component="img" src={calendar}></Box>
            <Typography className="text-sliderCard-3-landingPage">
              24th, july , 2024
            </Typography>
          </Box>
        </Box>
      </Box>
    </a>
  );
};

export default CourseCard;
