import "../bookYourDemo/BookYourDemo.scss";
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import bookYourDemo from "../../../assets/images/bookYourDemo/bookYourDemo.svg";
import gradient from "../../../assets/images/bookYourDemo/gradient.png";
import underline from "../../../assets/images/bookYourDemo/underline.png";
import arrowLine from "../../../assets/images/bookYourDemo/arrowLine.png";
import { useState, useRef } from "react";
import axios from "axios";
import { isEmpty } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";

const BookYourDemo = () => {
  const recaptchaRef = useRef(null);
  //
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  const [qualification, setQualification] = useState("");
  const qualificationOptions = ["Student", "Graduate", "Working Professional"];
  const [message, setMessage] = useState("");
  const [messageCode, setMessageCode] = useState("#000");
  const [loading, setLoading] = useState(false);

  const validation = () => {
    const mobileNumRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (isEmpty(name)) {
      setMessage("Please enter full name");
      setMessageCode("red");
      return false;
    } else if (
      isEmpty(mobileNum) ||
      mobileNum.length !== 10 ||
      !mobileNumRegex.test(mobileNum)
    ) {
      setMessage("Please enter valid mobile number");
      setMessageCode("red");
      return false;
    } else if (isEmpty(emailId) || !emailRegex.test(emailId)) {
      setMessage("Please enter valid Email Id");
      setMessageCode("red");
      return false;
    } else if (isEmpty(qualification)) {
      setMessage("Please select qualification");
      setMessageCode("red");
      return false;
    } else {
      setMessage("");
      setMessageCode("#000");
      return true;
    }
  };

  const handleSubmit = async () => {
    const recaptchaValue = recaptchaRef.current.getValue();

    if (validation()) {
      setMessage("Registering..");
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_LINK}/api/demoRegistration`,
          {
            name,
            emailId,
            mobileNum,
            qualification,
            recaptchaValue,
          }
        );
        if (response?.status === 201) {
          setMessage("Registered Successfully");
          setMessageCode("#02da15");
          setTimeout(() => {
            setMessage("");
            setMessageCode("#000");
          }, 3000);
          setName("");
          setEmailId("");
          setMobileNum("");
          setQualification("");
        } else {
          setMessageCode("red");
          setMessage("Please try again later");
          setTimeout(() => {
            setMessage("");
            setMessageCode("#000");
          }, 3000);
        }
      } catch (error) {
        console.error("There was an error!", error);
        setMessageCode("red");
        setMessage(error.response.data.message);
        setTimeout(() => {
          setMessage("");
          setMessageCode("#000");
        }, 3000);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Box className="demoWrapper" sx={{ overflow: "hidden" }}>
        <Box
          className="headerWrapper"
          sx={{ mt: { md: "53px", xs: "12px" }, mb: "48px" }}
        >
          <Box
            component="img"
            src={arrowLine}
            className="arrowLine"
            sx={{ left: { lg: "4%", sm: 0 } }}
          />
          <Typography
            className="headText"
            sx={{ fontSize: { xs: "20px", md: "36px" } }}
          >
            <span style={{ color: "#FE7465" }}>Try before you join! </span>
            Take up our free demo session today!
          </Typography>
          <Typography
            className="subText"
            sx={{ display: { xs: "none", sm: "contents" } }}
          >
            Have a chance to experience our immersive & interactive learning
            session with industry experts.
          </Typography>
        </Box>
        <Box
          sx={{
            m: { lg: "80px 64px", md: "80px 32px", xs: "40px 0px" },
            display: "flex",
            flexDirection: { lg: "row", xs: "column-reverse" },

            borderRadius: { md: "26px", xs: "12px" },
          }}
          className="contentWrapper"
        >
          <Box
            component="img"
            src={bookYourDemo}
            sx={{
              width: { xl: "60%", lg: "80%", xs: "100%" },
              height: "100%",
              borderRadius: { lg: "25px 0 0 25px", xs: "0 0 25px 25px" },
            }}
            className="bookingImg"
          ></Box>
          <Box
            sx={{
              width: { lg: "45%", xs: "100%" },
              ml: { xl: "-10%", lg: "-20%" },
              background: "linear-gradient(to right, #10303A, #123644)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              borderRadius: { lg: "0 25px 25px", xs: "25px 25px 0 0" },
            }}
          >
            <Box
              style={{ position: "relative", width: "100%" }}
              sx={{ ml: { lg: "-27%" } }}
            >
              <Box className="bookContent">
                <Box
                  className="contentform"
                  sx={{
                    width: { xs: "100%", sm: "70%", lg: "100%" },
                    mx: "16px",
                    borderRadius: { md: "25px 25px 0px 0px", xs: "12px" },
                  }}
                >
                  <Typography className="bookHeader">
                    <Typography className="bookHeader">
                      Attend our free masterclass!
                    </Typography>
                    <Box component="img" src={underline} />
                  </Typography>
                  <Box
                    className="formDetails"
                    sx={{ flexDirection: { md: "row", xs: "column" } }}
                  >
                    <Box
                      className="formInput halfInput"
                      sx={{ width: { md: "48%", xs: "100%" } }}
                    >
                      <Typography className="formInputHeader">
                        Full Name*
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant="outlined"
                        className="formText"
                      />
                    </Box>
                    <Box
                      className="formInput halfInput"
                      sx={{ width: { md: "48%", xs: "100%" } }}
                    >
                      <Typography className="formInputHeader">
                        Mobile Number*
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        value={mobileNum}
                        onChange={(e) => setMobileNum(e.target.value)}
                        variant="outlined"
                        className="formText"
                      />
                    </Box>
                  </Box>
                  <Box className="formInput">
                    <Typography className="formInputHeader">
                      Email ID*
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      variant="outlined"
                      fullWidth
                      className="formText"
                    />
                  </Box>
                  <Box sx={{ paddingBottom: "20px" }} className="formInput">
                    <Typography className="formInputHeader">
                      Qualification*
                    </Typography>
                    <Select
                      id="demo-simple-select"
                      fullWidth
                      displayEmpty
                      className="formText selectForm"
                      renderValue={(selected) => {
                        if (selected === "") {
                          return <>Select your qualification</>;
                        }
                        return selected;
                      }}
                      value={qualification}
                      onChange={(e) => setQualification(e.target.value)}
                    >
                      <MenuItem disabled value="">
                        Select your qualification
                      </MenuItem>
                      {qualificationOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  {/* Captcha component  */}

                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                    ref={recaptchaRef}
                  />

                  {/*  */}
                  <Button
                    variant="contained"
                    className="contactButton"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Register
                  </Button>
                  <Typography
                    className="freeTagline"
                    sx={{ color: messageCode }}
                  >
                    {message}
                  </Typography>
                </Box>
                <Box
                  component="img"
                  src={gradient}
                  className="gradientImg"
                  sx={{
                    right: { lg: "-27%", sm: "0" },
                    top: { lg: "-12%", sm: "3%" },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BookYourDemo;
