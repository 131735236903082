import { Box, Typography,Grid } from "@mui/material";
import cvPattern from "../../../assets/images/skills/cvPattern.png";
import group from "../../../assets/images/skills/Group.svg";
import triangle from "../../../assets/images/skills/triangle.svg";
import book from "../../../assets/images/skills/book.svg";
import "../skills/Skills.scss"

const Skills = ({courseContent}) => {
  const skills = courseContent?.skills 
  return (
    <>    <Box className="skillsWrapper" sx={{m:{md: "32px 32px 0px 32px", xs: "16px 16px 0px 16px" },pb:{md:"40px",xs:"26px"}}}>
      <Typography className="title" sx={{p:{md:"50px 20px 50px 20px", xs:"48px 16px 48px 16px"}, fontSize:{xs:"20px",md:"36px"}}}>
        <span style={{ color: "#FF7262" }}>Skills</span> You'll Learn
      </Typography>
      <Grid container spacing={{ xs: 1.25, sm: 3,md:4 }}  className="pointsWrapper " sx={{px:{md:"100px",sm:"24px" ,xs:"16px"}}}>
        {skills.map((skill, index) => (
          <Grid item xs={12} sm={8} md={4} key={index}>
            <Box className="bullet"  sx={{py:{xs:"9px",md:"13px"},pl:{xs:"9px",md:"23px"}}}>
              <Box component="img" src={cvPattern}/>
              <Typography className="text">{skill}</Typography>
            </Box>
          </Grid>
           
        ))}
      </Grid>
      <Box component="img" src={book}  sx={{pt:{xs:"100px",md:"24px"}}}/>

      <Box
    component="img"
    src={triangle}
    sx={{position: "absolute", top:{md: "28px",xs:"14px"}, right: {md: "18px",xs:"16px"} }}
  /> <Box
  component="img"
  src={group}
  sx={{position: "absolute", left:{md: "52px",xs:"16px"}, bottom: {md: "47px",xs:"96px"} }}
/>
    </Box>
    </>
  );
};

export default Skills;
