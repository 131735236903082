import { Box, Button, Dialog, IconButton, TextField, Typography } from "@mui/material";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';


const ShareDialogBox=({open, handleClose, endpoint})=>{
    
    const [copySuccess, setCopySuccess] = useState('');
    const handleCopy = () => {
        navigator.clipboard.writeText(`${window.location.href}/${endpoint}`).then(() => {
            setCopySuccess('Copied!');
        }, () => {
            setCopySuccess('Failed to copy');
        });
    };
    return(
        <Dialog open={open} onClose={handleClose}>
        <Box sx={{p:"20px"}}>
        <Typography>
             Share
             <IconButton
                 aria-label="close"
                 onClick={handleClose}
                 sx={{
                     position: 'absolute',
                     right: 8,
                     top: 8,
                     color: (theme) => theme.palette.grey[500],
                 }}
             >
                 <CloseIcon />
             </IconButton>
         </Typography>
         <Box>
             <Typography>
                 Copy the link below to share:
             </Typography>
             <TextField
                 autoFocus
                 margin="dense"
                 id="link"
                 type="text"
                 fullWidth
                 variant="outlined"
                 value={window.location.href+"/"+endpoint}
                 InputProps={{
                     readOnly: true,
                 }}
             />
             {copySuccess && <Typography color="success.main">{copySuccess}</Typography>}
         </Box>
         <Box>
             <Button onClick={handleCopy}>Copy</Button>
         </Box>
        </Box>
     </Dialog>
    )
}

export default ShareDialogBox;